import React from "react";
import Layout from "../components/Layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";

export default function BlogPost({ data, path }) {
  const { frontmatter, body, excerpt } = data.mdx;
  const { featuredImage, title, featuredImageAlt, imageAttrib } = frontmatter;
  const date = new Date(frontmatter.date);

  return (
    <Layout post={{ ...frontmatter, path: path, description: excerpt }}>
      <article className="c-blog mx-auto">
        <time dateTime={frontmatter.date}>{date.toLocaleDateString()}</time>
        <h1>{title}</h1>
        {featuredImage && (
          <div className="relative">
            <picture className="c-blog__feature-image">
              <source
                srcSet={featuredImage.childImageSharp.fluid.srcSet}
                sizes={featuredImage.childImageSharp.fluid.sizes}
              />
              <img src={featuredImage.childImageSharp.original.src} alt={featuredImageAlt} />
            </picture>
            {imageAttrib && (
              <a href={imageAttrib} target="_blank" rel="noopenner noreferrer" className="c-blog__feature-image-source">
                Image source
              </a>
            )}
          </div>
        )}
        <MDXRenderer>{body}</MDXRenderer>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 800, toFormat: AVIF) {
              src
              srcSet
              sizes
            }
            original {
              src
            }
          }
        }
        featuredImageAlt
        imageAttrib
      }
      body
      excerpt(pruneLength: 250)
      fields {
        slug
      }
    }
  }
`;
